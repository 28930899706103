import * as React from "react"
import { Container, Row, Col } from "react-bootstrap" 
import PlayVideo from "../../Play/CustomVideo"; 
import NewsBanner from "../../StaticPage/StaticBanner/NewsBanner"
import Sidebar from "../../Sidebar/Sidebar"
import Breadcrumbs from "../../Footer/Breadcrumb/Breadcrumb";
 
import _ from "lodash";
 
import SEO from "../../seo";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  // InstapaperShareButton,
} from "react-share";
import Getproperty from "../Getproperty/Getproperty";
import { inViewOptions, containerVariants } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
 
const NewsDetail = (props) => {
  
  const shareurl = typeof window !== 'undefined' ? window.location.href : ''
  
  const trackerShare = (event, scl_share_url) => {
    var shareURL = typeof window !== 'undefined' ? window.location.href : ''
    if (scl_share_url) {
      window.open(scl_share_url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600')
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Share - social icons',
      'formType': event + ' - ' + shareURL,
      'formId': 'Share',
      'formName': 'Share',
      'formLabel': 'Share'
    });
  }
  
  var videoDetail = props.video; 
  return (
    <>
      <SEO title={videoDetail.Meta_Title} description={`${videoDetail.Meta_Description} | DB Roberts`} image={videoDetail.Image.url} location={props.location} />
      <NewsBanner {...videoDetail} />
      <section className="guide-details">
        <Container>
          <Row>
            <Col xl={8} lg={7}>
              {videoDetail.Image &&
                <InView {...inViewOptions}>
                  {({ ref, inView }) => (
                    <motion.div
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={containerVariants}
                    >
                      <div className="history news-banner">
                        <div className="banner-img img-zoom">
                          <picture>
                            <source media="(min-width:992px)" srcSet={videoDetail.Image.url} />
                            <source media="(min-width:768px)" srcSet={videoDetail.Image.url} />
                            <img src={videoDetail.Image.url} alt={videoDetail.Image.alternativeText} />
                            {
                              videoDetail.Embed_Video_URL &&
                              <PlayVideo
                                url={videoDetail.Embed_Video_URL}
                                icon="icon-play"
                                class="play-btn"
                                buttonName="Play"
                                autoplay={videoDetail.AutoPlay}
                                repeat={videoDetail.Repeat}
                              />
                            }
                          </picture>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </InView>
              }
              <InView {...inViewOptions}>
                {({ ref, inView }) => (
                  <motion.div
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                  >
                    <div className="social-links">
                      <span className="sm-txt">Share this video:</span>
                      <span className="social-site">
                        <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                          <i className="icon-twitter"></i>
                        </TwitterShareButton>

                        <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                          <i className="icon-fb"></i>
                        </FacebookShareButton>

                        <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                          <i className="icon-linkedln"></i>
                        </LinkedinShareButton>

                        <a href={`javascript:;`} onClick={() => trackerShare('GplusShareButton', 'https://plus.google.com/share?url=' + shareurl)}>
                          <i className="icon-google"></i>
                        </a>

                        <a href={`javascript:;`} onClick={() => trackerShare('InstaShareButton', 'https://www.instagram.com/?url=' + shareurl)}>
                          <i className="icon-insta"></i>
                        </a>

                      </span>
                    </div>
                  </motion.div>
                )}
              </InView>
            </Col>
            <Col xl={4} lg={5}>
              <Sidebar type="static" />
            </Col>
          </Row>
        </Container>
      </section> 
      <Breadcrumbs tag="details" category="Videos" subCategory={null} data={[]} page={videoDetail.Title} />
      <Getproperty 
       Title={'Interested in joining the DB Roberts team?'}
       CTA_1_Label={`Join the Team`}
       CTA_1_URL={{id: "60e7e2b58b692f2baba49b42"}}
       CTA_2_Label={'Contact Us'}
       CTA_2_URL={{id: "60decfdf1e81aeb4beea1c24"}}
       />
    </>
  )
}
export default NewsDetail